@charset "UTF-8";
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.polar-container {
  flex-wrap: nowrap;
}

.summary {
  font-size: medium;
  margin-left: auto;
  padding-left:5px!important;
}

.GridData2 {
  height:48px!important;
  padding:5px!important;
}

.Unit2 {
  font-size: x-small; 
}

.Active2{
  background-color: #0000cd;
  font-size: x-small; 
  width:120px;
}


.NotActive2{
  background-color: #454d55;
  font-size: x-small; 
  width:120px;
}

.StarboardActive{
  background-color: #0000cd;
}
.StarboardNegative{
}


.ManualWind {
  font-size: large; 
}

.WindValue {
  font-size: x-large; 
  width    : 100px; 
  text-align: right; 
}

.SelectedValue {
  color: #d88884;
  font-size: 30;
}

@media (max-width: 992px) {
  .GridArea{
    height: 150px;
    padding: 0px!important;
  }
  .GraphArea {
    min-height: 90px!important;
  }
}

@media (min-width: 1200px) {

  .GridArea {
    /* background-color: #c8e6c9;*/
    height: 280px;
    width: 100%!important;
  }

  .GraphArea {
    min-height: 500px!important;
  }
}
