@charset "UTF-8";
 /* OpenLayer航跡マップ上に風向・風速をオーバレイするDiv設定 */
 .wind-direction-icon {
     color: #FFFFFF;
     font-size: 9pt;
     z-index: 100;
     position: absolute;
     width: 0px!important;
     height: 0px!important;
     border-radius: 50%;
     border: 1px solid white;
     background-color: rgb(50, 52, 58);
     top: 15px;
     right: 30px;
 }
 
 /* 子要素をセンタリングする基本設定 */
 .wind-direction-icon > .top-label,
 .wind-direction-icon > .arrow-label,
 .wind-direction-icon > .bottom-label {
     position: absolute;
     display: block;
     top: 50%;
     left: 50%;
     -ms-transform: translate(-50%, -50%);
     -webkit-transform: translate(-50%, -50%);
     transform: translate(-50%, -50%);
 }


 .wind-direction-icon > .top-label {
    top:-15px!important;
}
 
 /* WIND ラベル位置調整 */
 .wind-direction-icon > .top-label {
     top: 0%;
 }
 
 /* 風向アイコン位置調整 */
 .wind-direction-icon > .arrow-label > .arrow-img {
     width: 15px;
     height: 15px;
 }
 
 /* 風速 ラベル位置調整 */
 .wind-direction-icon > .bottom-label {
    top: 75%;
    margin-top: 20px!important;
 }

  /* 地図の表示領域 */
 .map-view{
     height: 70%;
 }

 .wind-unit{
  font-size: xx-small;
 }

.leaflet-control-zoom-in {
  width:20px!important;
  height:20px!important;
  line-height:20px!important;
}

.leaflet-control-zoom-out {
  width:20px!important;
  height:20px!important;
  line-height:20px!important;
}

.leaflet-container {
  width: 100%;
}
