 /* OpenLayer航跡マップ上に風向・風速をオーバレイするDiv設定 */
 .wind-direction-icon {
     color: #FFFFFF;
     font-size: 9pt;
     z-index: 100;
     position: absolute;
     width: 60px;
     height: 60px;
     border-radius: 50%;
     border: 1px solid white;
     background-color: rgb(50, 52, 58);
     top: 15px;
     right: 30px;
 }
 
 /* 子要素をセンタリングする基本設定 */
 .wind-direction-icon > .top-label,
 .wind-direction-icon > .arrow-label,
 .wind-direction-icon > .bottom-label {
     position: absolute;
     display: block;
     top: 50%;
     left: 50%;
     -ms-transform: translate(-50%, -50%);
     -webkit-transform: translate(-50%, -50%);
     transform: translate(-50%, -50%);
 }
 
 /* WIND ラベル位置調整 */
 .wind-direction-icon > .top-label {
     top: 20%;
 }
 
 /* 風向アイコン位置調整 */
 .wind-direction-icon > .arrow-label > .arrow-img {
     width: 15px;
     height: 15px;
 }
 
 /* 風速 ラベル位置調整 */
 .wind-direction-icon > .bottom-label {
    top: 100%;
 }

.leaflet-container {
  height: '40vh';
}

