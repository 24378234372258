@charset "UTF-8";
@import url(https://fonts.googleapis.com/css?family=Maven+Pro:400,900);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Maven Pro"
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8f8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace, ;
}

#root{
  font-size:14px;
  background-color: #f8f8f8;
  overflow: hidden;
}



html, body {
    padding: 0;
    height: 100%;
    width: 100%;
}



.leaflet-container {
    width: 100%;
    height: 100%;
    margin: 0px;
}

.tooltip {
    color: rgba(255, 255, 255, 1)!important;
    font-size: 10px;
    background-color: rgba(0, 0, 0, 0.6) !important;
    position:relative;
    left:  5px;
    top:  -15px;
    z-index: 999;
    opacity: 0.8;
}
