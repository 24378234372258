@charset "UTF-8";
.navbar {
  margin-bottom: 0px;
}

.left-side-menu {
  border: none;
}

.contents-area {
  max-width: 100%;
  /* margin-left: 24px; */
  /* margin-right: 0px; */
}

div.Dropdown-root {
  width: 100%;
  margin-top: 4px;
  margin-bottom: 16px;
  text-align: center;
}
div.Dropdown-control {
  margin-bottom: 8px;
}
div.Dropdown-menu { 
  width: 87%;
}

div#calendar {
  margin-bottom: 16px;
}

div#calendar,div.calendar-header,table.calendar-table {
  width: 100%;
}

div.calendar-header {
  margin-bottom: 16px;
}

div#calendar span {
  font-size: large;
}

div#calendar .button-area {
  float: right;
  margin-top: 4px;
  margin-right: 8px
}

button.calendar-header-prev {
  margin-right: 2px;
}

div#calendar th {
  text-align: center;
}
div#calendar td {
  text-align: center;
  font-family: auto;
}

div#calendar td.selected {
  color: #FFFFFF;
  background-color: rgb(255, 100, 38);
  border-radius: 16px
}

div#calendar td.practice {
  color: #FFFFFF;
  background-color: grey;
  border-radius: 16px
}

div#calendar td.practice:hover {
  background-color: #FF3310;
  cursor:pointer;
}

div.col-xs-5 > div.row {
  /* margin-left: 36px; */
}

li.selectedNode {
  color: #FFFFFF;
  background-color: #26453D;
}



/************************************************************************/
/*   LoadingAnimation  
/************************************************************************/
#loading{
	width: 100%;
	height: 100%;
  border: none;
  position: fixed;
  top: 0%;
  left: 0%;
  background-color: rgba(255, 255, 255, 0.7);
  filter: alpha(opacity=1.0);			/* IE */
  -moz-opacity:1.0;					      /* FireFox */
  opacity:1.0;						        /* Opera Safari */
  display: none;
  z-index: 1000;
}

#loadingCircle{
  text-align:center;
  padding-top:280px;
}

#loading.show {
  display: block !important;
}

#loading.hide{
  display: none !important;
}

.row{
  margin-left: 0px;
  margin-right:0px;
}

/* DataSelector の width */
.btn-group.btn-group-lg.btn-group-toggle {
  width: 100%
}

/* DataSelector の ボタンレイアウト（３等分） */
.btn-group.btn-group-lg.btn-group-toggle.btn-group-3 > .btn {
  width : 33.33333% ; /* レガシーブラウザ用フォールバック */
  width : -webkit-calc(100% / 3) ;
  width : calc(100% / 3) ;
  border-radius: 0;
  margin-right: 0;
  margin-left: 0;
}

/* DataSelector の ボタンレイアウト（5等分） */
.btn-group.btn-group-lg.btn-group-toggle.btn-group-5 > .btn {
  width : 20% ; /* レガシーブラウザ用フォールバック */
  width : -webkit-calc(100% / 5) ;
  width : calc(100% / 5) ;
  border-radius: 0;
  margin-right: 0;
  margin-left: 0;
}

/* DataSelector の ボタンレイアウト（6等分） */
.btn-group.btn-group-lg.btn-group-toggle.btn-group-6 > .btn {
  width : 1.6666666666% ; /* レガシーブラウザ用フォールバック */
  width : -webkit-calc(100% / 6) ;
  width : calc(100% / 6) ;
  border-radius: 0;
  margin-right: 0;
  margin-left: 0;
}

/* 風上VMG/風下VMG/速度 用データ選択ボタンの設定(通常・非選択状態) */
div.pr-graph1-selector > .btn {
  font-size: 15px;
  color: #585858;
  background-color:#f7f7f7;
  border-color: #aaaaaa;
}

/* 風上VMG/風下VMG/速度用データ選択ボタンの設定(マウスホバー) */
div.pr-graph1-selector > .btn:hover,
div.pr-graph1-selector > .btn.hover {
  color: whitesmoke;
  background-color: #ff5100;
  border-color: #aaaaaa;
}

/* 風上VMG/風下VMG/速度 用データ選択ボタンの設定(選択状態) */
div.pr-graph1-selector > .btn:active,
div.pr-graph1-selector > .btn.active {
  color: whitesmoke;
  background-color: #ff5100;
  border-color: #aaaaaa;
}


/* PITCH,ROLL,YAW,風向,風速 用データ選択ボタンの設定(通常・非選択状態) */
div.pr-graph2-selector > .btn {
  font-size: 12px;
  color: #585858;
  background-color:#f7f7f7;
  border-color: #aaaaaa;
  padding-left: 0px;
  padding-right: 0px;
}

/* PITCH,ROLL,YAW,風向,風速 用データ選択ボタンの設定(マウスホバー) */
div.pr-graph2-selector > .btn:hover,
div.pr-graph2-selector > .btn.hover {
  color: whitesmoke;
  background-color: #3a5a98;
  border-color: #aaaaaa;
}

/* PITCH,ROLL,YAW,風向,風速 用データ選択ボタンの設定(選択状態) */
div.pr-graph2-selector > .btn:active,
div.pr-graph2-selector > .btn.active {
  color: whitesmoke;
  background-color: #3a5a98;
  border-color: #aaaaaa;
}

/* スライダーのハンドルが div 領域からはみ出す分の微調整 */
.rc-slider {
    width: 98%;
    margin-left: auto;
    margin-right: auto;
}

/* TimeSeriesMap スライダー左右に時間を表示するエリアのスタイル */
.seektime {
    width: 100%;
    margin: 0 5px 0 5px;
}

/* OpenLayer航跡マップ の設定 */
.ol-tracking-map {
    background-color: #FAFAFA;
    /* OpenLayer を出す bootstrap Grid Row の高さが他のコンポーネントと
       併せられないため、固定で高さを出している */
    height: 410px;
    /* width: 100%; */
    position: relative;
    margin: 0px;
    padding: 2px;
}

/* OpenLayer航跡マップ上に風向・風速をオーバレイするDiv設定 */
.ol-tracking-map > .wind-direction-icon {
    color: #FFFFFF;
    font-size: 9pt;
    z-index: 100;
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid white;
    background-color: rgb(50, 52, 58);
    top: 15px;
    right: 30px;
}

/* 子要素をセンタリングする基本設定 */
.ol-tracking-map > .wind-direction-icon > .top-label,
.ol-tracking-map > .wind-direction-icon > .arrow-label,
.ol-tracking-map > .wind-direction-icon > .bottom-label {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

/* WIND ラベル位置調整 */
.ol-tracking-map > .wind-direction-icon > .top-label {
    top: 20%;
}

/* 風向アイコン位置調整 */
.ol-tracking-map > .wind-direction-icon > .arrow-label > .arrow-img {
    width: 15px;
    height: 15px;
}

/* 風速 ラベル位置調整 */
.ol-tracking-map > .wind-direction-icon > .bottom-label {
    top: 75%;
}

.component-gutters{
  margin : 0;
  padding: 2px;
}

/* Modal */
.ReactModal__Overlay {
  /* modal must be always top */
  z-index: 1000;
}

/* trash icon */
li.time-ranges > a > span.trash-icon {
  margin-left: 20px;
}

.proceed-to-export-button{
  text-align: right;
}

/************************************************************************/
/*   PracticeTree
/************************************************************************/

#practice-tree > ul > li > span.rc-tree-node-content-wrapper.rc-tree-node-content-wrapper-close{
  padding-bottom: 40px;
}
#practice-tree > ul > li > span.rc-tree-node-content-wrapper.rc-tree-node-content-wrapper-open{
  padding-bottom: 40px;
}

#practice-tree > ul > li > span.rc-tree-node-content-wrapper.rc-tree-node-content-wrapper-open > span.rc-tree-title > span > span.download-button{
  padding-right: 5px;
}

#practice-tree > ul > li > span.rc-tree-node-content-wrapper.rc-tree-node-content-wrapper-open > span.rc-tree-title > span > span.config-button > img{
  width: 18px;
  height: auto;
}

#practice-tree > ul > li > span.rc-tree-node-content-wrapper.rc-tree-node-content-wrapper-open > span.rc-tree-title > span > span.config-button{
}


#practice-tree > ul > li > span.rc-tree-node-content-wrapper.rc-tree-node-content-wrapper-open.rc-tree-node-selected,
#practice-tree > ul > li > span.rc-tree-node-content-wrapper.rc-tree-node-content-wrapper-open.rc-tree-node-selected,
#practice-tree > ul > li.practice-node.rc-tree-treenode-switcher-close.rc-tree-treenode-selected > span.rc-tree-node-content-wrapper.rc-tree-node-content-wrapper-close.rc-tree-node-selected,
#practice-tree > ul > li > ul > li.rc-tree-treenode-selected > span.rc-tree-node-content-wrapper.rc-tree-node-content-wrapper-normal.rc-tree-node-selected{
  color: black;
  background-color: inherit;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: medium solid #ff5100;
}

div.smoothing-filter > span {
  color: black;
  font-size: 15px;
  margin-right: 5px;
}

div.smoothing-filter > input[type="radio"]{
  margin-left: 10px;
  font-size: 15px;
}
