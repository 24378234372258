.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}


.GridData {
  font-size: xx-large;
}

.Unit {
  font-size: x-large;
}

.GridData2 {
  height:48px!important;
  padding:5px!important;
}

.Unit2 {
  font-size: x-small;
}

.RealtimeData {
  font-size: xx-large;
  margin-left: auto;
  padding-left:5px!important;
}

.Active{
  background-color: #0000cd;
}


.NotActive{
  background-color: #454d55;
}

.StarboardActive{
  background-color: #0000cd;
}
.StarboardNegative{
}


.ManualWind {
  font-size: large; 
}

.WindValue {
  font-size: x-large; 
  width    : 100px; 
  text-align: right; 
}
