@charset "UTF-8";
.page-wrapper {
  padding:30px;
}

#login-form{
  height:350px;
  padding:50px;
  background-color:#FFFFFF;
  border: 1px solid #e7e7e7;
}

#top-display{
  height:350px;
  padding:50px;
  background-color:#FFFFFF;
  border: 1px solid #e7e7e7;
}

#submit{
 background-color: #E4522F;
 border: #E4522F;
}


#forgot{
  float: right;
}
