/* polar-graph.scss
------------------------------------------------------------ */
@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 400;
  src: local("Noto Sans CJK JP"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.otf) format("opentype");
}

.polar-container {
  background-color: #101116;
  padding: 30px 0 20px;
  margin: 0;
  position: relative;
  font-family: 'Noto Sans CJK JP', 'Noto Sans JP', 'Arial', 'Hiragino Kaku Gothic ProN', Meiryo, sans-serif;
  line-height : 1.5;
  color : #fff;
  font-size : 10px;
}

.polar-container svg {
  vertical-align: middle;
  fill: currentColor;
}

.polar-container svg:not(:root) {
  overflow: hidden;
}

.polar-container .polar-title {
  text-align: center;
  color: #fff;
  font-size: 10px;
  padding-top: 25px;
  letter-spacing: 1px;
  text-indent: 1px;
}

.polar-container .polar-wind-speed-container {
  width: 53px;
  height: 53px;
  border-radius: 50%;
  background-color: #3E3E3F;
  position: absolute;
  top: 30px;
  right: 20px;
}

.polar-container .polar-wind-speed-container:before, .polar-container .polar-wind-speed-container:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  top: 50%;
}

.polar-container .polar-wind-speed-container:before {
  border-top: 5px solid transparent;
  border-bottom: 5px solid #fff;
  border-left: 3px solid transparent;
  border-right: 3px solid #fff;
  left: 50%;
  transform: translate(-100%, -50%) skewY(-25deg);
}

.polar-container .polar-wind-speed-container:after {
  border-top: 5px solid transparent;
  border-bottom: 5px solid #fff;
  border-left: 3px solid #fff;
  border-right: 3px solid transparent;
  right: 50%;
  transform: translate(100%, -50%) skewY(25deg);
}

.polar-container .polar-wind-speed-title {
  color: #fff;
  text-align: center;
  position: absolute;
  width: 100%;
  top: 4px;
  font-size: 10px;
  transform: scale(0.8);
  letter-spacing: 1px;
  text-indent: 1px;
}

.polar-container .polar-wind-speed {
  color: #fff;
  text-align: center;
  position: absolute;
  width: 100%;
  bottom: 4px;
  font-size: 10px;
  transform: scale(0.8);
  letter-spacing: 1px;
  text-indent: 1px;
}

.polar-container .polar-data-name-list {
  position: absolute;
  top: 30px;
  left: 20px;
  list-style : none;
  padding: 0;
}

.polar-container .polar-data-name-list li {
  position: relative;
  color: #fff;
  padding-left: 15px;
}

.polar-container .polar-data-name-list li + li {
  margin-top: 5px;
}

.polar-container .polar-data-name-dot {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  border-radius: 50%;
}

.polar-container .polar-data-axis-list {
  position: relative;
}

.polar-container .polar-svg-layer-bg {
  display: block;
  position: relative;
  z-index: 1;
  margin: 25px auto;
}

.polar-container .polar-svg-layer-highlights, .polar-container .polar-curve-container {
  display: block;
  position: absolute;
  top: 55px;
  left: 50%;
  transform: translateX(-50%);
}

.polar-container .polar-svg-layer-highlights {
  z-index: 3;
}

.polar-container .polar-curve-container {
  z-index: 4;
}

.polar-container .polar-svg-layer-axis-labels {
  position: absolute;
  top: 30px;
  left: 0;
  z-index: 2;
}

.polar-container svg {
  /* width:100%; */
  display: block;
}

.polar-container .polar-svg-axis-label {
  font-size: 20px;
  fill: #fff;
}

/* custom polar curve and axis */

.polar-container .tick line {
  stroke: #fff !important;
}

.polar-container .grid-circle {
  pointer-events: none !important;
}

.polar-container .outside-circle {
  stroke: none !important;
  /* stroke: #f00 !important; */
  fill: none !important;
}

.polar-container .mark.dot {
  display: none !important;
}

.polar-container .line {
  stroke-linecap: round !important;
  stroke-linejoin: round !important;
}

.polar-container .radial.axis-group > .tick:first-child {
  display: none !important;
}

.polar-container .angular.axis-group {
  display: none !important;
}

.polar-container .tick text {
  text-anchor: start !important;
  alignment-baseline: after-edge !important;
  font-size: 10px !important;
}

.polar-container .tick line {
  transform: translateY(-2.5px);
}

.polar-container .tooltips-group {
  display: none !important;
  pointer-events: none !important;
}

.polar-container .domain {
  display: none !important;
}

.icon svg {
  fill: #ff5c33;
}
